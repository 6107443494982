import React from "react"
import { graphql, Link } from "gatsby"
import get from "lodash/get"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

import styled from "styled-components"

import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <BlogPostParagraph>{children}</BlogPostParagraph>
    ),
    [INLINES.ENTRY_HYPERLINK]: ({
      data: {
        target: { slug, title },
      },
    }) => <Link to={slug}>{title}</Link>,

    [BLOCKS.EMBEDDED_ASSET]: node => (
      <BlogPostImageContainer>
        <BlogPostImage
          image={node.data.target.gatsbyImageData}
          alt={node.data.target.title}
        />
        {/* <BlogPostImage image={node.data.target} /> */}
        <BlogPostImageItemsWrapper>
          <BlogPostImageTitle>{node.data.target.title}</BlogPostImageTitle>
          <BlogPostImageDescription>
            {node.data.target.description}
          </BlogPostImageDescription>
        </BlogPostImageItemsWrapper>
      </BlogPostImageContainer>
    ),
    [BLOCKS.QUOTE]: (node, children) => <BlogQuote>{children}</BlogQuote>,
  },
}

class BlogPostContentful extends React.Component {
  render() {
    const post = get(this.props, "data.contentfulBlogPost")
    const siteTitle = get(this.props, "data.site.siteMetadata.title")
    const { previous, next } = get(this.props, "pageContext")

    return (
      <Layout page="blog" navBarColor="#181818" location={this.props.location}>
        <SEO title={`${post.title} | ${siteTitle}`} />
        <BlogPostTitle>{post.title}</BlogPostTitle>
        <BlogPostContent>
          {post.content && renderRichText(post.content, options)}
        </BlogPostContent>
        <ul>
          <li>
            {previous && (
              <Link to={previous.slug} rel="prev">
                {previous.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.slug} rel="prev">
                {next.title}
              </Link>
            )}
          </li>
        </ul>
      </Layout>
    )
  }
}

export default BlogPostContentful

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      category
      content {
        raw
        references {
          ... on Node {
            ... on ContentfulAsset {
              # contentful_id is required to resolve the references
              contentful_id
              title
              description
              __typename
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
const BlogPostContent = styled.div`
  min-height: 100vh;
  padding: 5rem calc((100vw - 680px) / 2);
  background: #fff;
  color: #121212;

  @media screen and (max-width: 1200px) {
    padding: 5rem 2rem;
  }

  @media screen and (max-width: 868px) {
    padding: 5rem 1rem;
  }
`

const BlogPostImageContainer = styled.div`
  padding: 2rem 0rem;
  text-align: center;
`

const BlogPostImage = styled(GatsbyImage)`
  margin-left: auto;
  margin-right: auto;
`

const BlogPostTitle = styled.h1`
  text-align: center;
  padding-top: 3rem;
  font-size: clamp(1.8rem, 2.5vw, 2.8rem);
`

const BlogPostParagraph = styled.p`
  font-size: 18px;
  line-height: 1.5rem;
`

const BlogQuote = styled.div``

const BlogPostImageItemsWrapper = styled.div``
const BlogPostImageTitle = styled.div`
  font-size: 14px;
  font-style: italic;
  padding: 0.5rem;
`
const BlogPostImageDescription = styled.div`
  font-size: 12px;
  font-style: italic;
`
